<template>
  <div class="rounded-lg bg-current pt-2">
    <div class="flex items-center justify-between px-8">
      <div class="flex">
        <button v-if="images" type="button" title="新增" @click="add()">
          <FontAwesome
            icon="plus-circle"
            type="fas"
            class="w-4 h-4 text-white"
          />
        </button>
      </div>
      <span class="text-white">共 {{ images?.length || 0 }} 項</span>
    </div>
    <div v-if="images?.length" class="container" :style="{ width: width }">
      <swiper
        observer
        observeParents
        resizeObserver
        grabCursor
        :key="version"
        :mousewheel="mousewheel"
        :navigation="navigation"
        :pagination="pagination ? { clickable: true } : undefined"
        :lazy="lazy"
        :slidesPerView="Math.min(images?.length || 1, pageSize)"
        :spaceBetween="itemSpace"
      >
        <swiper-slide
          class="border-0 rounded-lg"
          v-for="(image, index) in images"
          :key="index"
        >
          <div class="rounded-lg bg-transparent-pattern-mid">
            <img
              class="rounded-lg relative m-auto h-48 object-scale-down"
              :src="image.Uri"
              @click="edit(image)"
            />
          </div>
          <div class="absolute top-0 right-0 m-auto">
            <button type="button" title="刪除" @click="remove(image)">
              <FontAwesome
                icon="trash-alt"
                type="fas"
                class="w-6 h-6 text-red-500 p-1 rounded-tr-lg rounded-bl-lg bg-white opacity-70"
              />
            </button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="text-gray-500 text-2xl flex text-center px-4 pb-5" v-else>
      <div
        class="mx-1 mb-2 py-20 border border-gray-500 rounded-lg bg-transparent-pattern-mid w-1/3"
      >
        左上角 + 加入圖片
      </div>
      <div
        class="mb-2 py-20 border border-gray-500 rounded-lg bg-transparent-pattern-mid w-1/3"
      >
        左上角 + 加入圖片
      </div>
      <div
        class="mx-1 mb-2 py-20 border border-gray-500 rounded-lg bg-transparent-pattern-mid w-1/3"
      >
        左上角 + 加入圖片
      </div>
    </div>
  </div>
  <vxe-modal
    :title="`${editingImage?.Id ? '編輯' : '新增'}${title}`"
    transfer
    resize
    :loading="loading"
    show-footer
    v-model="modalVisible"
  >
    <div class="w-full text-center">
      <file-uploader
        ref="uploader"
        name="files"
        extensions="png,gif,jpg,jpeg"
        accept="image/png,image/gif,image/jpeg"
        :limitedWidth="itemWidth"
        :limitedHeight="itemHeight"
        :multiple="!editingImage?.Id"
        :action="filePostAction"
        @filter="uploaderFilter"
      />
    </div>
    <vxe-form
      ref="form"
      v-bind="formOptions"
      :data="editingImage"
      @submit="save(editingImage)"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
    </vxe-form>
    <template #footer>
      <vxe-button
        type="submit"
        status="primary"
        content="確認"
        @click="
          $refs.form.validate(errMap => {
            if (errMap === undefined) $refs.form.dispatchEvent('submit');
          })
        "
      />
    </template>
  </vxe-modal>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>

<script>
import { defineComponent, ref } from "@cloudfun/core";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Mousewheel
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import FileUploader from "@/cloudfun/components/FileUploader.vue";

import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination, Autoplay, Mousewheel]);

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    FileUploader
  },
  props: {
    mousewheel: { type: Boolean, default: true },
    navigation: { type: Boolean, dafault: true },
    pagination: { type: Boolean, default: true },
    pageSize: { type: Number, default: 3 },
    itemSpace: { type: Number, default: 3 },
    itemWidth: [Number, String],
    itemHeight: [Number, String],
    lazy: { type: Boolean, dafault: true },
    width: String,
    title: { type: String, default: "相片" },
    filePostAction: String,
    modelValue: Array,
    formOptions: {
      type: Object,
      default: () => {
        return {
          titleWidth: 40,
          titleAlign: "right",
          items: [
            {
              field: "Name",
              title: "名稱",
              span: 24,
              itemRender: {
                name: "$input",
                props: { placeholder: "請輸入文字", clearable: true }
              }
            }
          ]
        };
      }
    },
    onRefresh: Function,
    onAdd: Function,
    onEdit: Function,
    onSave: Function,
    onRemove: Function
  },
  setup(props) {
    const uploader = ref({});
    const images = ref(props.modelValue);
    const version = ref(0);
    const loading = ref(false);
    const editingImage = ref(null);
    const modalVisible = ref(false);
    return {
      uploader,
      images,
      version,
      loading,
      editingImage,
      modalVisible
    };
  },
  // watch: {
  //   modelValue(current, original) {
  //     console.log("...", current, original);
  //   },
  // },
  methods: {
    uploaderFilter(current, original, prevent) {
      if (!current) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    reload(images) {
      const action = () => {
        this.images = images;
        this.$emit("update:modelValue", this.images);
      };
      if (this.$props.onRefresh) this.$emit("refresh");
      else action();
    },
    add() {
      this.editingImage = {};
      this.modalVisible = true;
      const action = async () => {
        this.modalVisible = true;
        while (!("previewSrc" in this.uploader)) {
          await new Promise(resolve => {
            setTimeout(() => resolve(), 10);
          });
        }
        this.uploader.previewSrc = undefined;
        this.uploader.files = [];
      };
      if (this.$props.onAdd) this.$emit("add", this.editingImage, action);
      else action();
    },
    async edit(image) {
      this.editingImage = image;
      const action = async () => {
        this.modalVisible = true;
        while (!("previewSrc" in this.uploader)) {
          await new Promise(resolve => {
            setTimeout(() => resolve(), 10);
          });
        }
        this.uploader.previewSrc = image.Uri;
        this.uploader.files = [];
      };
      if (this.$props.onEdit) this.$emit("edit", image, action);
      else action();
    },
    async save(image) {
      const params = { insertRows: [], updateRows: [] };
      if (this.uploader.files.length) {
        this.uploader.files.forEach(e => {
          e.active = true;
        });
        await this.uploader.upload().then(files => {
          files.forEach(file => {
            image.Uri = file.response.payload?.length
              ? file.response.payload[0]
              : undefined;
            image.ContentType = file.type;
            if (image.Id) params.updateRows.push({ ...image });
            else params.insertRows.push({ ...image });
          });
        });
      } else if (image.Id) params.updateRows.push({ ...image });
      else {
        alert("請選擇上傳的照片");
        return;
      }
      const action = () => {
        this.$emit("update:modelValue", this.images);
        this.loading = false;
        this.modalVisible = false;
      };
      this.loading = true;
      if (this.$props.onSave) this.$emit("save", params, action);
      else action();
    },
    remove(image) {
      if (image && confirm("確定要進行刪除嗎?")) {
        const action = () => {
          const index = this.images.indexOf(image);
          if (index >= 0) this.images.splice(index, 1);
          this.$emit("update:modelValue", this.images);
        };
        if (this.$props.onRemove) this.$emit("remove", image, action);
        else action();
      }
    }
  }
});
</script>
